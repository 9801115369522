import { axiosPublic } from '../api/axios'
import { CLIENT_ID } from '../Constants'
import axiosRetry from 'axios-retry'
import useAuth from "./useAuth"

const useRefreshToken = () => {
  const { setAuth } = useAuth()

  const refresh = async () => {
    try {
      axiosRetry(axiosPublic, {
        retries: 10,
        retryDelay: axiosRetry.exponentialDelay,
        retryCondition: (error) => {
          return error.response.status >= 500;
        }
      })
      const q = `/auth/refresh-tokens?client_id=${CLIENT_ID}`
      const response = await axiosPublic
        .get(q, { withCredentials: true })
        .then(r => r.data.accessToken)
      console.log(`token4: ${response.data.accessToken}`)
      setAuth((previousState) => {
        console.log(`state4: ${JSON.stringify(previousState)}`)
        console.log(`token4: ${response.data.accessToken}`)
        return { ...previousState, accessToken: response.data.accessToken }
      })
    } catch (err) {
      console.log(`error4: ${JSON.stringify(err)}`)
      return false
    }

    return true
  }

  return refresh
}

export default useRefreshToken