import LinkPage from './components/LinkPage'
import Unauthorized from './components/Unauthorized'
import Home from './components/Home'
import Editor from './components/Editor'
import Admin from './components/Admin'
import Lounge from './components/Lounge'
import Missing from './components/Missing'
import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
import Auth from './components/Auth'

const ROLES = {
  'Admin': 'admin',
  'Editor': 'editor',
  'User': 'user'
}

const precedence = (group) => {
  if (group === 'admin') {
    console.log(`group=${group}, 'admin'`)
    return 100
  }
  else if (group === 'editor') {
    console.log(`group=${group}, 'editor'`)
    return 200
  }
  else if (group === 'user') {
    console.log(`group=${group}, 'user'`)
    return 300
  }
  else {
    console.log(`group=${group}, 'default'`)
    return 1000
  }
}

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />} >

        {/* public routes */}
        <Route path='linkpage' element={<LinkPage />} />
        <Route path='unauthorized' element={<Unauthorized />} />
        <Route path='auth' element={<Auth />} />

        {/* protected routes */}
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} precedence={precedence} />}>
          <Route path='/' element={<Home />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} precedence={precedence} />}>
          <Route path='editor' element={<Editor />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} precedence={precedence} />}>
          <Route path='admin' element={<Admin />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} precedence={precedence} />}>
          <Route path='lounge' element={<Lounge />} />
        </Route>

        {/* catch all */}
        <Route path='*' element={<Missing />} />

      </Route>
    </Routes>
  );
}

export default App;
