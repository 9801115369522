import { useEffect, useState } from "react"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useNavigate, useLocation } from "react-router-dom"

const Users = () => {
  const [users, setUsers] = useState()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get('/hello-world/hello/X', {
          signal: controller.signal
        })
        console.log(response.data)
        isMounted && setUsers(response.data)
      } catch (err) {
        console.log(err)
        //navigate('/login', { state: { from: location }, replace: true })
      }
    }

    getUsers()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  return (
    <article>
      <h2>User List:</h2>
      {users?.length
        ? (
          <ul>
            {users.map((user, i) => (
              <li key={i}>{user?.firstname}, {user?.lastname}</li>
            ))}
          </ul>
        ) : (
          <p>No users to display.</p>
        )
      }
      <br />
    </article>
  )
}

export default Users