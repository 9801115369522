import React, { useEffect, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { axiosPublic } from '../api/axios'
import { CLIENT_ID } from '../Constants'
import axiosRetry from 'axios-retry'

const Auth = () => {
  const { auth, setAuth } = useAuth()

  const [searchParams] = useSearchParams()
  const code = searchParams.get("code")
  const state = searchParams.get("state")

  const [error, setError] = useState()

  const getTokens = async () => {
    try {
      axiosRetry(axiosPublic, {
        retries: 5,
        retryDelay: axiosRetry.exponentialDelay,
        retryCondition: () => true
      })
      const q = `/auth/get-tokens?client_id=${CLIENT_ID}&code=${code}&redirect_uri=https://www.alazeya.org/auth&prevent_redirect=true`
      const response = await axiosPublic
        .get(q, { withCredentials: true })
        .then(r => r?.data)

      const accessToken = response?.accessToken
      const idToken = response?.idToken
      const groups = response?.groups

      setAuth({ accessToken, idToken, groups })

    } catch (err) {
      console.log(`error: ${JSON.stringify(err)}`)
      setError(err)
    }
  }

  useEffect(() => {
    getTokens()
  }, [])

  return (
    auth?.accessToken
      ? <Navigate to={state} />
      : error
        ? <h1>request failed</h1>
        : <h1>waiting...</h1>
  )
}

export default Auth