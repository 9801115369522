import { Outlet, Navigate, useLocation } from "react-router-dom"
import useAuth from "../hooks/useAuth"
import { CLIENT_ID } from "../Constants"
import { axiosPublic } from '../api/axios'
import axiosRetry from 'axios-retry'
import { useEffect, useState } from "react"

const AUTH_STATUS = {
  'AUTHORIZED': 'AUTHORIZED',
  'UNAUTHORIZED': 'UNAUTHORIZED',
  'NOT_LOGGED_IN': 'NOT_LOGGED_IN'
}

const LoginPage = ({ location }) => {
  const domain = 'alazeya.org'
  const callback = 'https://www.alazeya.org/auth'
  const responseType = 'code'
  const scope = 'aws.cognito.signin.user.admin+email+openid+phone+profile'
  const state = encodeURI(location)
  window.location.replace(`https://auth.${domain}/login?client_id=${CLIENT_ID}&response_type=${responseType}&scope=${scope}&redirect_uri=${callback}&state=${state}`)
  return null
}

const getPath = (location) => {
  const path = location.pathname || ''
  const search = location.search || ''
  const hash = location.hash || ''
  return [path, search, hash].join('')
}

const minPrecedence = (groups, precedence) => {
  return Array.isArray(groups)
    ? Math.min(...groups.map(group => precedence(group)))
    : null
}

const isAuthorized = (allowedRoles, groups, precedence) => {
  const minUserPrecedence = groups
    ? minPrecedence(groups, precedence)
    : 1000

  const minAllowedPrecedence = minPrecedence(allowedRoles, precedence)
  console.log(`minUserPrecedence: ${minUserPrecedence} <= minAllowedPrecedence: ${minAllowedPrecedence}`)

  return minUserPrecedence <= minAllowedPrecedence
    ? AUTH_STATUS.AUTHORIZED
    : AUTH_STATUS.UNAUTHORIZED
}

const RequireAuth = ({ allowedRoles, precedence }) => {
  const { auth, setAuth } = useAuth()
  const location = useLocation()
  const state = getPath(location)

  const [authStatus, setAuthStatus] = useState()

  const refresh = async (setAuthStatus) => {
    try {
      axiosRetry(axiosPublic, {
        retries: 30,
        retryDelay: axiosRetry.exponentialDelay,
        retryCondition: (error) => {
          return error.response.status >= 500;
        }
      })
      const q = `/auth/refresh-tokens?client_id=${CLIENT_ID}`
      const response = await axiosPublic
        .get(q, { withCredentials: true })
        .then(r => r)
      console.log(`response: ${JSON.stringify(response)}`)
      setAuth((previousState) => {
        return {
          ...previousState,
          accessToken: response.data.accessToken,
          groups: response.data.groups
        }
      })
      console.log(`after setAuth`)
      const authStatus = isAuthorized(allowedRoles, response.data.groups, precedence)
      console.log(`authStatus1: ${authStatus}`)
      setAuthStatus(authStatus)
    } catch (err) {
      console.log(`authStatus2: NOT_LOGGED_IN: ${JSON.stringify(err)}`)
      setAuthStatus(AUTH_STATUS.NOT_LOGGED_IN)
    }
  }

  useEffect(() => {
    if (!auth?.accessToken) {
      refresh(setAuthStatus)
    } else {
      setAuthStatus(isAuthorized(allowedRoles, auth?.groups, precedence))
    }
  }, [])

  return (
    !authStatus
      ? <h3>waiting...</h3>
      : authStatus === AUTH_STATUS.AUTHORIZED
        ? <Outlet />
        : authStatus === AUTH_STATUS.UNAUTHORIZED
          ? <Navigate to="/unauthorized" state={state} replace />
          : <LoginPage location={state} />
  )
}

export default RequireAuth
