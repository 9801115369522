import axios from 'axios'

const BASE_URL = 'https://api.alazeya.org'

export default axios.create({
  baseURL: BASE_URL
})

export const axiosPublic =  axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json'},
  withCredentials: false
})

export const axiosPrivate =  axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json'},
  withCredentials: true
})